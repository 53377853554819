import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import keycloak from './Keycloak';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

keycloak.init({ onLoad: 'login-required' }).then((authenticated) => {
    if (authenticated) {
        root.render(
            <React.StrictMode>
                <App />
            </React.StrictMode>
        );
    } else {
        window.location.reload();
    }

    // Token refresh setup
    setInterval(() => {
        keycloak.updateToken(70).then((refreshed) => {
            if (refreshed) {
                console.log('Token refreshed');
            } else {
                console.warn('Token not refreshed, valid for ', Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000), ' seconds');
            }
        }).catch(() => {
            console.error('Failed to refresh token');
        });
    }, 60000);

}).catch((err) => {
    console.error('Failed to initialize Keycloak');
});
